<template>
  <div
    class="
      participant-card
      tw-w-full
      tw-border
      tw-gap-2
      tw-flex
      tw-flex-row
      tw-justify-between
      tw-items-start
      tw-p-2
      tw-my-2
      tw-relative
      tw-rounded-lg
    "
    :class="isMe ? 'tw-bg-slate-100' : ''"
  >
    <div
      class="
        tw-flex-auto tw-flex-col tw-justify-start tw-items-start tw-text-left
      "
    >
      <p class="tw-font-bold">Name: {{ agent.Name }}</p>
      <p>ID: {{ agent.AgentID }}</p>
    </div>
    <div
      class="
        action-status
        tw-flex-none tw-flex tw-flex-col tw-w-min tw-h-min tw-gap-1
      "
    >
      <span class="badge tw-bg-sky-300">{{ agent.Role }}</span>
      <span
        class="badge"
        :class="agent.IsActive ? 'tw-bg-green-400' : 'tw-bg-red-400'"
        >{{ agent.IsActive ? "Online" : "Offline" }}</span
      >
    </div>
    <button v-if="!isMe" class="tw-flex-none" @click="onUnassignAgent">
      <svg
        class="tw-w-5 tw-h-5 tw-text-red-400 tw-fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
        />
      </svg>
    </button>
    <div v-else class="tw-w-5"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ParticipantCard",
  props: {
    agent: Object,
    removeable: Boolean,
    status: Boolean,
    value: Object,
  },
  computed: {
    ...mapGetters({
      user: "userInfo",
    }),
    isMe() {
      const id = this.user.userCode;
      return this.agent.AgentID === id;
    },
  },
  methods: {
    handlerInput(e) {
      console.log(e, this.agent);
    },
    onUnassignAgent() {
      console.log("unassign", this.agent);
      this.$emit("unassign-agent", this.agent);
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0 !important;
}

.badge {
  @apply tw-text-xs tw-inline-block tw-py-1 tw-px-2.5 tw-leading-none tw-text-center tw-whitespace-nowrap tw-align-baseline tw-font-bold tw-text-white tw-rounded tw-top-2 tw-right-2;
}
</style>
